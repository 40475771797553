<template>
  <div class="cases" v-if="$route.name === 'cases'">
    <div class="container">
      <div class="cases__inner">
        <div class="cases__title">
          <h1>{{ $t('cases_page.title') }}</h1>
        </div>
        <div class="cases__text">{{ $t('cases_page.text') }}</div>

        <div class="cases__tabs">
          <div class="cases__tabs-list">
            <span class="cases__tabs-text">{{ $t('cases_page.category') }}</span>
            <ul>
              <li v-for="typeCaseButton in typeCasesButtons" :key="typeCaseButton.id">
                <button type="button" class="control" :data-filter="typeCaseButton.class">
                  {{ $t(typeCaseButton.type) }}
                </button>
              </li>
            </ul>


            <div class="cases__tabs-body tabs">
              <div class="tabs__wrapper mix" v-for="typeCase in typeCases" :key="typeCase.id" :class="typeCase.class">
                <router-link :to="typeCase.projectUrl" style="display: flex; height: 100%;">
                  <div class="tabs__item">
                    <div class="tabs__image">
                      <picture>
                        <source :srcset="typeCase.projectImageWebp" type="image/webp">
                        <img :src="typeCase.projectImagePng" alt="" loading="lazy" />
                      </picture>
                    </div>
                    <div class="tabs__body">
                      <div class="tabs__title">{{ $t(typeCase.projectTitle) }}</div>
                      <div>
                      <div>
                        <span v-for="(projectType, index) in typeCase.type" :key="index">{{ $t(projectType) }}</span>
                      </div>
                      <div class="tabs__text">{{ $t(typeCase.projectText) }}</div>
                      <div class="tabs__button">

                        <router-link :to="typeCase.projectUrl" class="btn__general">
                          {{ $t('cases_page.cases_prevent.button_more') }}
                          <svg width="31" height="8" viewBox="0 0 31 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M30.3536 4.35355C30.5488 4.15829 30.5488 3.84171 30.3536 3.64645L27.1716 0.464466C26.9763 0.269204 26.6597 0.269204 26.4645 0.464466C26.2692 0.659728 26.2692 0.976311 26.4645 1.17157L29.2929 4L26.4645 6.82843C26.2692 7.02369 26.2692 7.34027 26.4645 7.53553C26.6597 7.7308 26.9763 7.7308 27.1716 7.53553L30.3536 4.35355ZM0 4.5H30V3.5H0V4.5Z"
                              fill="white" />
                          </svg>
                        </router-link>
                      </div>
                    </div>
                    </div>
                  </div>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Contact />
  </div>
  <router-view />


</template>

<script>

import Contact from "../components/Contact_1";
import mixitup from "mixitup";
import { computed, reactive } from "vue";
import { useHead } from "@vueuse/head";

export default {
  name: "Cases",
  components: {
    Contact
  },
  setup() {
    const siteData = reactive({
      title: "Case studies and projects | Webmil Web-production",
      description: "Explore how we implement ideas on different platforms, technologies, and industries for our clients. Contact us if you have any queries: info@webmil.eu",
      keywords: "cases, case studies, portfolio",
      robots: "index"
    })

    useHead({
      title: computed(() => siteData.title),
      meta: [
        {
          name: `description`,
          content: computed(() => siteData.description)
        },
        {
          name: `keywords`,
          content: computed(() => siteData.keywords)
        },
        {
          name: `robots`,
          content: computed(() => siteData.robots)
        },
      ],
    })
  },
  data() {
    return {
      typeCasesButtons: [
        { id: 1, class: "all", type: "cases_page.category_list[0]" },
        { id: 2, class: ".enterprise", type: "cases_page.category_list[1]" },
        { id: 3, class: ".cybersecurity", type: "cases_page.category_list[2]" },
        { id: 4, class: ".startups", type: "cases_page.category_list[3]" },
        { id: 5, class: ".media", type: "cases_page.category_list[4]" },
        { id: 6, class: ".advertisement", type: "cases_page.category_list[5]" },
        { id: 7, class: ".e-commerce", type: "cases_page.category_list[6]" }
      ],

      typeCases: [
        {
          id: 1,
          class: "enterprise",
          type: ["cases_page.cases_prevent.ukrpol.type"],
          projectTitle: "cases_page.cases_prevent.ukrpol.projectTitle",
          projectText: "cases_page.cases_prevent.ukrpol.projectText",
          projectUrl: "/cases/ukrpol_ua/",
          projectImagePng: require("../assets/cases/ukrpol/ukrpol_case.png"),
          projectImageWebp: require("../assets/cases/ukrpol/ukrpol_case.webp")
        },
        {
          id: 2,
          class: "cybersecurity startups",
          type: ["cases_page.cases_prevent.oversecured.type"],
          projectTitle: "cases_page.cases_prevent.oversecured.projectTitle",
          projectText: "cases_page.cases_prevent.oversecured.projectText",
          projectUrl: "/cases/oversecured/",
          projectImagePng: require("../assets/cases/oversecured/oversecured_case.png"),
          projectImageWebp: require("../assets/cases/oversecured/oversecured_case.webp")
        },
        {
          id: 3,
          class: "enterprise",
          type: ["cases_page.cases_prevent.oe.type"],
          projectTitle: "cases_page.cases_prevent.oe.projectTitle",
          projectText: "cases_page.cases_prevent.oe.projectText",
          projectUrl: "/cases/oe/",
          projectImagePng: require("../assets/cases/oe/oe_image_presentation.png"),
          projectImageWebp: require("../assets/cases/oe/oe_image_presentation.webp")
        },
        {
          id: 4,
          class: "enterprise",
          type: ["cases_page.cases_prevent.lobbyx.type"],
          projectTitle: "cases_page.cases_prevent.lobbyx.projectTitle",
          projectText: "cases_page.cases_prevent.lobbyx.projectText",
          projectUrl: "/cases/lobbyx/",
          projectImagePng: require("../assets/lobbyx_image_presentation2.png"),
          projectImageWebp: require("../assets/lobbyx_image_presentation2.webp")
        },
        {
          id: 5,
          class: "cybersecurity",
          type: ["cases_page.cases_prevent.hackenproof.type"],
          projectTitle: "cases_page.cases_prevent.hackenproof.projectTitle",
          projectText: "cases_page.cases_prevent.hackenproof.projectText",
          projectUrl: "/cases/hackenproof/",
          projectImagePng: require("../assets/hackenproof_image_presentation2.png"),
          projectImageWebp: require("../assets/hackenproof_image_presentation2.webp")
        },
        {
          id: 6,
          class: "media advertisement",
          type: ["cases_page.cases_prevent.bigboard.type"],
          projectTitle: "cases_page.cases_prevent.bigboard.projectTitle",
          projectText: "cases_page.cases_prevent.bigboard.projectText",
          projectUrl: "/cases/bigboard/",
          projectImagePng: require("../assets/bigboard_image_presentation2.png"),
          projectImageWebp: require("../assets/bigboard_image_presentation2.webp")
        },
        {
          id: 7,
          class: "enterprise",
          type: ["cases_page.cases_prevent.kochut.type"],
          projectTitle: "cases_page.cases_prevent.kochut.projectTitle",
          projectText: "cases_page.cases_prevent.kochut.projectText",
          projectUrl: "/cases/kochut/",
          projectImagePng: require("../assets/cases/kochut/kochut_case.png"),
          projectImageWebp: require("../assets/cases/kochut/kochut_case.webp")
        },
        {
          id: 8,
          class: "enterprise",
          type: ["cases_page.cases_prevent.palac_potocki.type"],
          projectTitle: "cases_page.cases_prevent.palac_potocki.projectTitle",
          projectText: "cases_page.cases_prevent.palac_potocki.projectText",
          projectUrl: "/cases/palac-potocki/",
          projectImagePng: require("../assets/cases/palace/Potocki_case.png"),
          projectImageWebp: require("../assets/cases/palace/Potocki_case.webp")
        },
        {
          id: 9,
          class: "e-commerce",
          type: ["cases_page.cases_prevent.kultura_kavy.type"],
          projectTitle: "cases_page.cases_prevent.kultura_kavy.projectTitle",
          projectText: "cases_page.cases_prevent.kultura_kavy.projectText",
          projectUrl: "/cases/kultura_kavy/",
          projectImagePng: require("../assets/cases/kulturaKavy/kultura_kavy_case.png"),
          projectImageWebp: require("../assets/cases/kulturaKavy/kultura_kavy_case.webp")
        },
        {
          id: 10,
          class: "media",
          type: ["cases_page.cases_prevent.d4u.type"],
          projectTitle: "cases_page.cases_prevent.d4u.projectTitle",
          projectText: "cases_page.cases_prevent.d4u.projectText",
          projectUrl: "/cases/d4u/",
          projectImagePng: require("../assets/cases/d4u/d4u_case.png"),
          projectImageWebp: require("../assets/cases/d4u/d4u_case.webp")
        },
        {
          id: 11,
          class: "media",
          type: ["cases_page.cases_prevent.tnmk.type"],
          projectTitle: "cases_page.cases_prevent.tnmk.projectTitle",
          projectText: "cases_page.cases_prevent.tnmk.projectText",
          projectUrl: "/cases/tnmk/",
          projectImagePng: require("../assets/cases/tnmk/tnmk_case.png"),
          projectImageWebp: require("../assets/cases/tnmk/tnmk_case.webp")
        }
      ]
    }
  },
  mounted() {
    let tabsInner = document.querySelector('.cases__tabs-body');
    if (!tabsInner) {
      return false
    }
    // eslint-disable-next-line no-unused-vars
    let mixer = mixitup(tabsInner, {
      animation: {
        duration: 300
      }
    });
  }
}
</script>

<style lang="scss">
@import "../base/styles/global.css";


.cases {
  padding-top: 183px;

  @media(max-width: 991px) {
    padding-top: 135px;
  }

  @media(max-width: 575px) {
    padding-top: 96px;
  }

  &__title {
    h1 {
      font-weight: bold;
      font-size: 72px;
      line-height: 72px;
      color: #2E3139;

      @media(max-width: 991px) {
        font-size: 60px;
        line-height: 66px;
      }

      @media(max-width: 575px) {
        font-size: 48px;
        line-height: 60px;
      }
    }
  }

  &__text {
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    color: #2E3139;
    max-width: 461px;
    margin-top: 30px;

    @media(max-width: 575px) {
      margin-top: 6px;
    }
  }

  &__tabs {
    margin-top: 55px;

    &-list {
      span {
        font-weight: 700;
        font-size: 18px;
        line-height: 28px;
        color: #2E3139;
      }
    }

    ul {
      display: flex;
      flex-wrap: wrap;
      list-style: none;
      margin-top: 15px;

      li {
        margin-right: 50px;
        padding: 5px 0px;
        cursor: pointer;
      }

      button {
        background: transparent;
        border: none;
        outline: none;
        transition: 0.4s color;
        font-weight: 400;
        font-size: 18px;
        line-height: 28px;

        @media(max-width: 575px) {
          font-weight: 400;
          font-size: 14px;
          line-height: 24px;
        }
      }

      button:hover {
        color: #688E93;
      }
    }

    &-body {
      padding-top: 45px;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      column-gap: 20px;
      row-gap: 61px;

      @media(max-width: 992px) {
        grid-template-columns: 1fr;
      }

      @media(max-width: 575px) {
        row-gap: 30px;
      }
    }

    .tabs__item {
      color: #2E3139;
      padding-top: 223px;
      position: relative;
      border: 1px solid #F2F2F2;
      width: 100%;
      height: 508px;
      @media(max-width: 992px){
        height: auto;
      }

      span {
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: #3675AF;
        transition: 0.6s color;

        @media(max-width: 575px) {
          font-size: 12px;
          line-height: 18px;
        }
      }

      span:not(:first-child) {
        margin-left: 10px;
      }

      a {
        transition: 0.4s color;
      }

      a:hover {
        color: #688E93;
      }
    }

    .tabs__image {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 223px;
      z-index: -4;
      transition: 0.5s all;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: top;
      }
    }

    .tabs__title {
      font-weight: 700;
      font-size: 24px;
      line-height: 36px;
      color: #2E3139;
      margin-bottom: 10px;
      transition: 0.6s color;

      @media(max-width: 575px) {
        font-weight: 700;
        font-size: 18px;
        line-height: 28px;
      }
    }

    .tabs__text {
      font-weight: 400;
      font-size: 18px;
      line-height: 28px;
      color: #454C4F;
      margin-top: 10px;
      transition: 0.6s color;

      @media(max-width: 575px) {
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
      }
    }

    .tabs__button {
      margin-top: 20px;
      flex: 1 1 auto;
      display: flex;
      align-items: flex-end;

      a {
        border: 1px solid #2E3139 !important;
        font-weight: 700 !important;
        font-size: 18px !important;
        line-height: 28px !important;
        color: #2E3139 !important;
        transition: 0.6s all;

        svg>path {
          fill: #2E3139 !important;
        }
      }

      @media(max-width: 575px) {
        display: none;
      }
    }

    @media (any-hover: hover) {
      .tabs__item:hover .tabs__image {
        height: 100%;
        filter: brightness(70%);
      }

      .tabs__item:hover .tabs__title {
        color: #FFF;
      }

      .tabs__item:hover .tabs__button {
        a {
          background-color: #FFF !important;
          border: 1px solid #FFF !important;
        }
      }

      .tabs__item:hover .tabs__text {
        color: #FFF;
      }

      .tabs__item:hover span {
        color: #FFF;
      }
    }

    .tabs__body {
      padding: 10px 20px 30px 20px;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      @media(max-width: 575px) {
        padding: 10px 10px 30px 10px;
      }
    }
  }
}
</style>
